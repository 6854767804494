import * as React from "react"
import { Link } from "gatsby"
import Logo from "../../../svg/logo_fiorenzato.svg"

const Header = () => (
  <header>
    <div className="container">
      <div className="logo-wrapper">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  </header>
)

export default Header
