import React from "react"
import Instagram from "../../../svg/nstagram.svg"
import Facebook from "../../../svg/facebook.svg"
import Youtube from "../../../svg/youtube.svg"

const Footer = ({ footerData }) => {
  return (
    <footer className="footer-wrapper">
      <div className="container-fluid">
        <div className="contacts-wrap">
          <ul className="contacts">
            <li className="contacts-link">
              Tel:
              <a href={`tel:${footerData?.telefono}`} target="blank">
                {footerData?.telefono}
              </a>
            </li>
            <li className="contacts-link">
              Email:
              <a href={`mailto:${footerData?.email}`}>{footerData?.email}</a>
            </li>
            <li className="contacts-link">
              Indirizzo:
              <a href={footerData?.google_map} target="blank">
                {footerData?.indirizzo}
              </a>
            </li>
          </ul>
        </div>

        <span className="line"></span>
        <div className="row wrapper">
          <div className="col-xl-6 col-md-6 col-sm-12">
            <ul className="legal-wrap">
              <li className="legal-wrap__items">
                <a href="https://www.fiorenzato.com/it/note-legali/">
                  Note legali
                </a>
              </li>
              <li className="legal-wrap__items">
                <a href="https://www.fiorenzato.com/it/privacy-policy/">
                  Privacy Policy
                </a>
              </li>
              <li className="legal-wrap__items">
                <a href="https://www.fiorenzato.com/it/cookie-policy/">
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12">
            <ul className="socials">
              <li className="socials-link">
                <a href={footerData?.facebook} target="blank">
                  <Facebook />
                </a>
              </li>
              <li className="socials-link">
                <a href={footerData?.instagram} target="blank">
                  <Instagram />
                </a>
              </li>
              <li className="socials-link">
                <a href={footerData?.youtube} target="blank">
                  <Youtube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
